import React, { useState } from "react";
import "./RegisterUser.scss";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";

function RegisterUser(props) {
  const [isModalCreateOpened, setIsModalCreateOpened] = useState(false);
  const [newUser, setNewUser] = useState({});
  const [typeOfModal, setTypeOfModal] = useState("");

  const schema = yup.object().shape({
    fullname: yup
      .string()
      .min(4, "Must be longer than 4 letters")
      .max(30, "Can't be longer than 30 letters")
      .matches(/^[A-Za-z\s]*$/, "Use only letters and space")
      .required("Fullname is not valid"),
    username: yup
      .string()
      .min(4, "Must be longer than 4 letters")
      .max(16, "Can't be longer than 16 letters")
      .matches(/^[A-Za-z0-9]*$/, "Use only letters and numbers")
      .required("Username is not valid"),
    email: yup.string().email().required("Email is not valid"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fullname: "",
      username: "",
      email: "",
    },
  });

  return (
    <div className="RegisterUser">
      <h1 className="RegisterUser__heading">Register new user</h1>
      <form className="RegisterUser__form">
        <label htmlFor="fullname" className="RegisterUser__label">
          Fullname:
          {errors.fullname && (
            <p className="RegisterUser__ErrorLog">{errors.fullname.message}</p>
          )}
        </label>
        <input
          className="RegisterUser__input"
          type="text"
          name="fullname"
          id="fullname"
          placeholder="John Doe"
          {...register("fullname")}
          required
        />
        <label htmlFor="username" className="RegisterUser__label">
          Username:
          {errors.username && (
            <p className="RegisterUser__ErrorLog">{errors.username.message}</p>
          )}
        </label>
        <input
          className="RegisterUser__input"
          type="text"
          name="username"
          id="username"
          placeholder="user1"
          {...register("username")}
          required
        />
        <label htmlFor="email" className="RegisterUser__label">
          E-mail:
          {errors.email && (
            <p className="RegisterUser__ErrorLog">{errors.email.message}</p>
          )}
        </label>
        <input
          className="RegisterUser__input"
          type="text"
          name="email"
          id="email"
          placeholder="mail@example.com"
          {...register("email")}
          required
        />
        <div>
          <Button
            className={`Button--light`}
            clickHandler={handleSubmit((d) => {
              if (errors.fullname || errors.username || errors.email) {
                return;
              }
              setNewUser({
                fullname: d.fullname,
                username: d.username,
                email: d.email,
              });
              setTypeOfModal("userCreate");
              setIsModalCreateOpened(true);
            })}
          >
            Create user
          </Button>
        </div>
      </form>
      <Modal
        type={typeOfModal}
        changeType={setTypeOfModal}
        isOpened={isModalCreateOpened}
        setOpened={setIsModalCreateOpened}
        data={newUser}
        setData={setNewUser}
        refreshList={props.refreshList}
        resetForm={reset}
        adminUuid={props.adminUuid}
        jwt={props.jwt}
      />
    </div>
  );
}

export default RegisterUser;
