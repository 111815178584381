import React, { useState } from "react";
import "./UserList.scss";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";

function UserList(props) {
  const [typeOfModal, setTypeOfModal] = useState("");
  const [user, setUser] = useState({});
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <div className="UserList">
      <h1 className="UserList__heading">Users list</h1>
      {props.users.length === 0 ? (
        <p className="UserList__empty">No users created</p>
      ) : (
        <table className="Table">
          <thead className="Table__heading">
            <tr>
              <th className="Table__heading">Fullname</th>
              <th className="Table__heading">Username</th>
              <th className="Table__heading">E-mail</th>
              {/* <th className="Table__heading Table__BorderNone"></th> */}
            </tr>
          </thead>
          <tbody>
            {props.users.map((user, i) => (
              <tr key={i}>
                <td key={i + 0.1}>{user.full_name}</td>
                <td key={i + 0.2}>{user.username}</td>
                <td key={i + 0.3}>{user.email}</td>
                <td className="Table__BorderNone">
                  {
                    <Button
                      className={`Button--light Button--text-sm`}
                      keyId={i + 0.4}
                      clickHandler={() => {
                        setUser(user);
                        setTypeOfModal("newPassword");
                        setIsModalOpened(true);
                      }}
                    >
                      Generate new password
                    </Button>
                  }
                </td>
                <td className="Table__BorderNone  Table__Delete">
                  {
                    <Button
                      className={`Button--error Button--text-sm`}
                      keyId={i + 0.4}
                      clickHandler={() => {
                        setUser(user);
                        setTypeOfModal("userDelete");
                        setIsModalOpened(true);
                      }}
                    >
                      Delete user
                    </Button>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        type={typeOfModal}
        changeType={setTypeOfModal}
        isOpened={isModalOpened}
        setOpened={setIsModalOpened}
        data={user}
        setData={setUser}
        deleteUser={props.deleteUser}
        adminUuid={props.adminUuid}
        refreshList={props.refreshList}
        jwt={props.jwt}
      />
    </div>
  );
}

export default UserList;
