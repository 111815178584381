import React, { useState } from "react";
import "./ImagePreviews.scss";
import UploadedImage from "./UploadedImage/UploadedImage";
import CountedImage from "./CountedImage/CountedImage";

function ImagePreviews(props) {
  const [countedImage, setCountedImage] = useState(null);
  const [countingCurrently, setCountingCurrently] = useState(false);
  const [numberCounted, setNumberCounted] = useState(null);

  return (
    <div className="ImagePreviews">
      <h1 className="ImagePreviews__heading">Upload image to count crowd</h1>
      <div className="ImagePreviews__images-container">
        <UploadedImage
          uuid={props.uuid}
          setCountedImage={setCountedImage}
          setCounting={setCountingCurrently}
          setNumberCounted={setNumberCounted}
          isCounted={Boolean(countedImage)}
        />
        <CountedImage
          uuid={props.uuid}
          countedImage={countedImage}
          isCounting={countingCurrently}
          numberCounted={numberCounted}
        />
      </div>
    </div>
  );
}

export default ImagePreviews;
