import React from "react";
import "./LoginForm.scss";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "yup-phone-lite";
import { useForm } from "react-hook-form";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { useSignOut } from "react-auth-kit";

function LoginForm(props) {
  const schema = yup.object().shape({
    username: yup.string().required("Credentials are incorrect"),
    password: yup.string().required("Credentials are incorrect"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const signIn = useSignIn();

  const signOut = useSignOut();

  const navigate = useNavigate();
  return (
    <form className="LoginForm">
      <label htmlFor="username" className="LoginForm__label">
        Username
      </label>
      <input
        className="LoginForm__input"
        name="username"
        id="username"
        type="text"
        placeholder="username"
        {...register("username")}
        required
      />
      <label htmlFor="password" className="LoginForm__label">
        Password
      </label>

      <input
        className="LoginForm__input"
        name="password"
        id="password"
        type="password"
        autoComplete="on"
        placeholder="password"
        {...register("password")}
        required
      />
      {(errors.password || errors.username) && (
        <p className="LoginForm__ErrorLog">
          {errors.username ? errors.username.message : errors.password.message}
        </p>
      )}
      <div className="LoginForm__ButtonsContainer">
        <Button
          className={`Button--light`}
          clickHandler={handleSubmit(async (d) => {
            await fetch("https://facecount.xyz/crowd_counting/login", {
              method: "POST", // Use the POST method for sending data
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                // other headers if needed
              },
              // We convert the React state to JSON and send it as the POST body
              body: JSON.stringify(d),
            }).then(function (response) {
              if (response.status === 200) {
                response.json().then(async (data) => {
                  signOut();
                  signIn({
                    token: data.access_token,
                    expiresIn: 3600,
                    tokenType: "Bearer",
                    authState: {
                      uuid: JSON.parse(atob(data.access_token.split(".")[1]))
                        .sub,
                      role: "user",
                      jwt: data.access_token,
                    },
                  });
                  setTimeout(() => {
                    navigate("/user");
                  }, 0);
                });
              }
            });
          })}
        >
          Log in as User
        </Button>
        <Button
          className={`Button--light`}
          clickHandler={handleSubmit(async (d) => {
            await fetch("https://facecount.xyz/crowd_counting/login_admin", {
              method: "POST", // Use the POST method for sending data
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                // other headers if needed
              },
              // We convert the React state to JSON and send it as the POST body
              body: JSON.stringify(d),
            }).then(function (response) {
              if (response.status === 200) {
                response.json().then((data) => {
                  signOut();
                  signIn({
                    token: data.access_token,
                    expiresIn: 3600,
                    tokenType: "Bearer",
                    authState: {
                      uuid: JSON.parse(atob(data.access_token.split(".")[1]))
                        .sub,
                      role: "admin",
                      jwt: data.access_token,
                    },
                  });
                  setTimeout(() => {
                    navigate("/admin");
                  }, 0);
                });
              }
            });
          })}
        >
          Log in as Admin
        </Button>
      </div>
    </form>
  );
}

export default LoginForm;
