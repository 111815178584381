import React from "react";
import "./Modal.scss";
import Button from "../Button/Button";

function Modal(props) {
  const closeModalHandler = () => {
    props.setOpened(false);
    props.setData({});
  };

  const createNewUser = async (data, jwt) => {
    await fetch(`https://facecount.xyz/crowd_counting/admin/register`, {
      method: "POST", // Use the POST method for sending data
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        Authorization: `Bearer ${jwt}`,
        // other headers if needed
      },
      // We convert the React state to JSON and send it as the POST body
      body: JSON.stringify({
        username: data.username,
        full_name: data.fullname,
        email: data.email,
      }),
    }).then(function (response) {
      if (response.status === 200) {
        props.changeType("created");
        props.refreshList();
        props.resetForm();
        response.blob().then((data) => {
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = "FILENAME";
          a.click();
        });
      } else if (response.status === 400) {
        props.changeType("exists");
      }
    });
  };

  const deleteUser = async (user_uuid, jwt) => {
    await fetch(
      `https://facecount.xyz/crowd_counting/admin/delete/${user_uuid}`,
      {
        method: "DELETE", // Use the POST method for sending data
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `Bearer ${jwt}`,
          // other headers if needed
        },
        // We convert the React state to JSON and send it as the POST body
      }
    ).then(function (response) {
      if (response.status === 200) {
        props.changeType("deleted");
        response.json().then((data) => {
          props.refreshList();
        });
      }
    });
  };

  const generateNewPassword = async (user_uuid, jwt) => {
    await fetch(
      `https://facecount.xyz/crowd_counting/admin/update_password/${user_uuid}`,
      {
        method: "GET", // Use the POST method for sending data
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `Bearer ${jwt}`,
          // other headers if needed
        },
        // We convert the React state to JSON and send it as the POST body
      }
    ).then(function (response) {
      if (response.status === 200) {
        props.changeType("generatedPassword");
        response.blob().then((data) => {
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = "User Info";
          a.click();
        });
      }
    });
  };

  return (
    <div>
      {props.isOpened && props.type === "userCreate" && (
        <div className="Modal__container">
          <div className="Overlay" onClick={closeModalHandler}></div>
          <div className="Modal">
            <h2 className="Modal__heading">
              Review informations for creating user
            </h2>
            <div className="Modal__infoContainer">
              <p className="Modal__info">Fullname: {props.data.fullname}</p>
              <p className="Modal__info">Username: {props.data.username}</p>
              <p className="Modal__info">E-mail: {props.data.email}</p>
            </div>
            <div className="Modal__ButtonContainer">
              <Button
                clickHandler={closeModalHandler}
                className={`Button--error`}
              >
                Cancel
              </Button>
              <Button
                className={`Button--light`}
                clickHandler={() => {
                  createNewUser(props.data, props.jwt);
                }}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      )}
      {props.isOpened && props.type === "created" && (
        <div className="Modal__container">
          <div className="Overlay" onClick={closeModalHandler}></div>
          <div className="Modal" style={{ justifyContent: "space-evenly" }}>
            <h2 className="Modal__heading">User created successfully</h2>
            <Button
              clickHandler={closeModalHandler}
              className={`Button--error`}
            >
              Close
            </Button>
          </div>
        </div>
      )}
      {props.isOpened && props.type === "exists" && (
        <div className="Modal__container">
          <div className="Overlay" onClick={closeModalHandler}></div>
          <div className="Modal" style={{ justifyContent: "space-evenly" }}>
            <h2 className="Modal__heading">User already exists</h2>
            <Button
              clickHandler={closeModalHandler}
              className={`Button--error`}
            >
              Close
            </Button>
          </div>
        </div>
      )}
      {props.isOpened && props.type === "userDelete" && (
        <div className="Modal__container">
          <div className="Overlay" onClick={closeModalHandler}></div>
          <div className="Modal">
            <h2 className="Modal__heading">Do you want to delete this user?</h2>
            <div className="Modal__infoContainer">
              <p className="Modal__info">Fullname: {props.data.full_name}</p>
              <p className="Modal__info">Username: {props.data.username}</p>
              <p className="Modal__info">E-mail: {props.data.email}</p>
            </div>
            <div className="Modal__ButtonContainer">
              <Button
                clickHandler={closeModalHandler}
                className={`Button--error`}
              >
                Cancel
              </Button>
              <Button
                className={`Button--light`}
                clickHandler={() => {
                  deleteUser(props.data.user_uuid, props.jwt);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      )}
      {props.isOpened && props.type === "deleted" && (
        <div className="Modal__container">
          <div className="Overlay" onClick={closeModalHandler}></div>
          <div className="Modal" style={{ justifyContent: "space-evenly" }}>
            <h2 className="Modal__heading">User deleted successfully</h2>
            <Button
              clickHandler={closeModalHandler}
              className={`Button--error`}
            >
              Close
            </Button>
          </div>
        </div>
      )}
      {props.isOpened && props.type === "newPassword" && (
        <div className="Modal__container">
          <div className="Overlay" onClick={closeModalHandler}></div>
          <div className="Modal">
            <h2 className="Modal__heading">
              Do you want to generate new password for this user?
            </h2>
            <div className="Modal__infoContainer">
              <p className="Modal__info">Fullname: {props.data.full_name}</p>
              <p className="Modal__info">Username: {props.data.username}</p>
              <p className="Modal__info">E-mail: {props.data.email}</p>
            </div>
            <div className="Modal__ButtonContainer">
              <Button
                clickHandler={closeModalHandler}
                className={`Button--error`}
              >
                Cancel
              </Button>
              <Button
                className={`Button--light`}
                clickHandler={() => {
                  generateNewPassword(props.data.user_uuid, props.jwt);
                }}
              >
                Generate
              </Button>
            </div>
          </div>
        </div>
      )}
      {props.isOpened && props.type === "generatedPassword" && (
        <div className="Modal__container">
          <div className="Overlay" onClick={closeModalHandler}></div>
          <div className="Modal" style={{ justifyContent: "space-evenly" }}>
            <h2 className="Modal__heading">Password updated successfully</h2>
            <h2 className="Modal__heading">
              PDF with password will be downloaded now
            </h2>
            <Button
              clickHandler={closeModalHandler}
              className={`Button--error`}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Modal;
