import React from "react";
import "./Button.scss";

function Button(props) {
  const classes = `Button ${props.className}`;

  return (
    <button
      key={props.keyId ? props.keyId : null}
      className={classes}
      onClick={props.clickHandler}
    >
      {props.children}
    </button>
  );
}

export default Button;
