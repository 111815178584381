import React, { useState } from "react";
import "./UploadedImage.scss";
import Button from "../../../../components/Button/Button";
import { useAuthUser } from "react-auth-kit";

function UploadedImage(props) {
  const [selectedImage, setSelectedImage] = useState(null);

  const authData = useAuthUser();
  const jwt = authData().jwt;

  const getCountedImage = async (jwt) => {
    await fetch(`https://facecount.xyz/crowd_counting/user/download`, {
      method: "GET", // Use the POST method for sending data
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        Authorization: `Bearer ${jwt}`,
      },
      // We convert the React state to JSON and send it as the POST body
    })
      .then((response) => response.blob())
      .then((blob) => {
        const imageURL = URL.createObjectURL(blob);
        props.setCounting(false);
        props.setCountedImage(imageURL);
      })
      .catch((error) => {});
  };

  const sendImage = async (imgObj, jwt) => {
    await fetch(`https://facecount.xyz/crowd_counting/user/image`, {
      method: "POST", // Use the POST method for sending data
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        Authorization: `Bearer ${jwt}`,
      },
      // We convert the React state to JSON and send it as the POST body
      body: imgObj,
    }).then(function (response) {
      if (response.status === 200) {
        response.json().then((data) => {
          props.setNumberCounted(data.crowd_number);
        });
        getCountedImage(jwt);
      } else {
        alert("Image processing failed");
        props.setCounting(false);
        props.setCountedImage(null);
        props.setNumberCounted(null);
        setSelectedImage(null);
      }
    });
  };

  const uploadImageHandler = async (user_uuid, imgObj) => {
    const imageInput = document.createElement("input");
    imageInput.type = "file";
    imageInput.name = "image";
    imageInput.id = "image";

    imageInput.addEventListener("change", async (e) => {
      setSelectedImage(e.target.files[0]);
      const form = new FormData();
      form.append("image", e.target.files[0]);
      props.setCountedImage(null);
      props.setNumberCounted(null);
      props.setCounting(true);
      await sendImage(form, jwt);
    });
    imageInput.click();
  };

  return (
    <div className="UploadedImage">
      {!selectedImage && (
        <Button className={"Button--light"} clickHandler={uploadImageHandler}>
          Upload Image
        </Button>
      )}
      {selectedImage && (
        <div style={{ marginBottom: "16px" }}>
          <h1 className="UploadedImage__desc">Original Image</h1>
          <img
            alt="not found"
            width={"100%"}
            src={URL.createObjectURL(selectedImage)}
          />
        </div>
      )}
      {props.isCounted && (
        <Button className={"Button--light"} clickHandler={uploadImageHandler}>
          Count Again
        </Button>
      )}
    </div>
  );
}

export default UploadedImage;
