import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useIsAuthenticated } from "react-auth-kit";

function Navigate() {
  const navigate = useNavigate();
  const authData = useAuthUser();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated()) {
      if (authData().role) {
        const role = authData().role;
        if (role === "admin") {
          navigate("/admin");
        } else if (role === "user") {
          navigate("/user");
        }
      }
    }
    navigate("/");
  });

  return <div></div>;
}

export default Navigate;
