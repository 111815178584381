import React, { useEffect, useState } from "react";
import "./Admin.scss";
import Header from "../../components/Header/Header";
import UserList from "./UserList/UserList";
import RegisterUser from "./RegisterUser/RegisterUser";
import { useAuthUser } from "react-auth-kit";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

function Admin(props) {
  const [adminData, setAdminData] = useState({});

  const [users, setUsers] = useState([]);

  const authData = useAuthUser();
  const uuid = authData().uuid;
  const role = authData().role;
  const jwt = authData().jwt;

  const signOut = useSignOut();

  const refreshUserList = () => {
    getAllUsersInfo(uuid, jwt);
  };

  const getAllUsersInfo = async (admin_uuid, jwt) => {
    if (admin_uuid) {
      await fetch(`https://facecount.xyz/crowd_counting/admin/all_users`, {
        method: "GET", // Use the POST method for sending data
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `Bearer ${jwt}`,
          // other headers if needed
        },
        // We convert the React state to JSON and send it as the POST body
      }).then(function (response) {
        if (response.status === 200) {
          response.json().then((data) => {
            setUsers(data);
          });
        }
      });
    }
  };

  const handleAdminInfo = async (admin_uuid, jwt) => {
    if (admin_uuid) {
      await fetch(`https://facecount.xyz/crowd_counting/admin`, {
        method: "GET", // Use the POST method for sending data
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `Bearer ${jwt}`,
          // other headers if needed
        },
        // We convert the React state to JSON and send it as the POST body
      }).then(function (response) {
        if (response.status === 200) {
          response.json().then((data) => {
            setAdminData(data);
          });
        }
      });
    }
  };

  const handleLogOut = async (jwt) => {
    if (jwt) {
      await fetch(`https://facecount.xyz/crowd_counting/logout`, {
        method: "POST", // Use the POST method for sending data
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `Bearer ${jwt}`,
          // other headers if needed
        },
        // We convert the React state to JSON and send it as the POST body
      }).then(function (response) {});
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (role === "user") {
      setTimeout(() => {
        navigate("/user");
      }, 0);
    }
  }, []);

  useEffect(() => {
    handleAdminInfo(uuid, jwt);
    getAllUsersInfo(uuid, jwt);
  }, [uuid, jwt]);

  return (
    <div className="Admin">
      <Header
        role={"admin"}
        data={adminData}
        logOut={() => {
          signOut();
          handleLogOut(jwt);
        }}
      />
      <RegisterUser refreshList={refreshUserList} adminUuid={uuid} jwt={jwt} />
      <UserList
        users={users}
        adminUuid={uuid}
        refreshList={refreshUserList}
        jwt={jwt}
      />
    </div>
  );
}

export default Admin;
