import React, { useEffect } from "react";
import "./Login.scss";
import LoginForm from "./LoginForm/LoginForm";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";

function Login(props) {
  const authData = useAuthUser();
  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      if (authData().role) {
        const role = authData().role;
        if (role === "admin") {
          navigate("/admin");
        } else if (role === "user") {
          navigate("/user");
        }
      }
    }
  }, []);

  return (
    <div className="Login">
      <h1 className="Login__header">Login</h1>
      <LoginForm handleUuid={props.handleUuid} />
    </div>
  );
}

export default Login;
