import React from "react";
import "./Header.scss";
import Button from "../Button/Button";

function Header(props) {
  return (
    <div className="Header">
      <h1 className="Header__heading">
        {props.role === "admin" ? "Admin Panel" : null}
        {props.role === "user" ? "Crowd Count" : null}
      </h1>
      <div className="Header__user-info-container">
        <p className="Header__user-info">Fullname: {props.data.full_name}</p>
        <p className="Header__user-info">Username: {props.data.username}</p>
        <p className="Header__user-info">E-mail: {props.data.email}</p>
      </div>
      <Button className={`Button--dark`} clickHandler={props.logOut}>
        Log Out
      </Button>
    </div>
  );
}

export default Header;
