import React from "react";
import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import { useIsAuthenticated } from "react-auth-kit";
import Admin from "./pages/Admin/Admin";
import User from "./pages/User/User";
import Navigate from "./components/Navigate/Navigate";

function App() {
  const PrivateRoute = ({ Component }) => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();
    return auth ? <Component /> : <Navigate />;
  };
  document.title = "Crowd Count";
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/admin" element={<PrivateRoute Component={Admin} />}></Route>
      <Route path="/user" element={<PrivateRoute Component={User} />}></Route>
      <Route path="*" element={<Navigate />} />
    </Routes>
  );
}

export default App;
