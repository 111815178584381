import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import ImagePreviews from "./ImagePreviews/ImagePreviews";
import { useAuthUser } from "react-auth-kit";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

function User(props) {
  const [userData, setUserData] = useState({});

  const authData = useAuthUser();
  const uuid = authData().uuid;
  const role = authData().role;
  const jwt = authData().jwt;

  const signOut = useSignOut();

  const handleUserInfo = async (user_uuid, jwt) => {
    if (user_uuid) {
      await fetch(`https://facecount.xyz/crowd_counting/user`, {
        method: "GET", // Use the POST method for sending data
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `Bearer ${jwt}`,
          // other headers if needed
        },
        // We convert the React state to JSON and send it as the POST body
      }).then(function (response) {
        if (response.status === 200) {
          response.json().then((data) => {
            setUserData(data);
          });
        }
      });
    }
  };

  const handleLogOut = async (jwt) => {
    if (jwt) {
      await fetch(`https://facecount.xyz/crowd_counting/logout`, {
        method: "POST", // Use the POST method for sending data
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `Bearer ${jwt}`,
          // other headers if needed
        },
        // We convert the React state to JSON and send it as the POST body
      }).then(function (response) {});
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (role === "admin") {
      setTimeout(() => {
        navigate("/admin");
      }, 0);
    }
  }, []);

  useEffect(() => {
    handleUserInfo(uuid, jwt);
  }, [uuid, jwt]);

  return (
    <div>
      <Header
        role={"user"}
        data={userData}
        logOut={() => {
          signOut();
          handleLogOut(jwt);
        }}
      />
      <ImagePreviews uuid={uuid} />
    </div>
  );
}

export default User;
