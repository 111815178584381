import React from "react";
import "./CountedImage.scss";
import MoonLoader from "react-spinners/ClipLoader";
import Button from "../../../../components/Button/Button";

function CountedImage(props) {
  const downloadCountedImage = () => {
    const a = document.createElement("a");
    a.href = props.countedImage;
    a.download = "counted image";
    a.click();
  };

  return (
    <div className="CountedImage">
      {!props.countedImage && !props.isCounting && (
        <p className="CountedImage__empty">Preview of counted image</p>
      )}
      {props.countedImage && (
        <h1 className="CountedImage__desc">Processed Image</h1>
      )}
      {props.countedImage && (
        <img alt="not found" width={"100%"} src={props.countedImage} />
      )}
      {props.countedImage && (
        <Button className={"Button--light"} clickHandler={downloadCountedImage}>
          Download image
        </Button>
      )}
      {props.isCounting && (
        <MoonLoader color="#223862" loading={props.isCounting} size={80} />
      )}
    </div>
  );
}

export default CountedImage;
